export type Pages = ((string | undefined)[] | undefined)[];

export const sample: Pages = [
    [
        "昔々、あるところに大きなカブがありました。\nあまりにも大きいので、誰も抜くことができず、ますます大きく育ったカブです。",
        "そこへおじいさんとおばあさんがやってきました。\nおじいさんとおばあさんは言いました。",
        "「なんて大きなカブだろう。ぜひこのカブを抜いて、持って帰りたいものだ」",
        "おじいさんがカブを引っ張ります。\nうんとこしょ、どっこいしょ！\nおばあさんがおじいさんを引っ張ります。\nうんとこしょ、どっこいしょ！\n大きなカブ太郎がやってきて、おばあさんを引っ張ります。\nうんとこしょ、どっこいしょ！",
        "だけども、カブは抜けません。\nイヌがやってきて、大きなカブ太郎を引っ張ります。\nうんとこしょ、どっこいしょ！\nネコがやってきて、イヌを引っ張ります。\nうんとこしょ、どっこいしょ！\nネズミがやってきて、ネコを引っ張ります。\nうんとこしょ、どっこいしょ！",
        "とうとう大きなカブはすっぽり抜けて、おじいさんとおばあさんと大きなカブ太郎は大きなカブで作ったボルシチを食べて、末長く幸せに暮らしましたとさ。",
    ],
    [
        "大きなカブはある日考えました。",
        "「こんなカブをいったい誰が抜くのだろう？　そんな人がいるなら、顔を見てみたいものだ！」",
        "「そして、大きなカブ太郎と名付けよう！」",
        "それから何年もの時がすぎました。",
        "大きなカブはどんなに頑張っても抜けませんでした。\n大きなカブ太郎のことはみんな忘れてしまいましたが、大きなカブは今もそこに生えています。",
    ],
    [
        "「ぼくはこの畑しか知らない。もっと広い世界を見てみたい」",
        "「だったら、俺が抜いてやる」と突然、森から屈強な鬼が現れました。",
        "「大きなカブ太郎とは、俺のための名前だ！」とのぶとい叫び声があたりにこだましました。",
        "ひとりでに抜けた大きなカブは「俺が大きなカブ太郎だ！」と大きな声で叫んだのです。\nその声は遥か海の向こうでも聞こえたと言います。",
    ],
    [
        "こうしてカブは世界を見るための旅に出ました。\nうんとこしょ、どっこいしょ！\n大きなカブは抜けないので、周りの地面ごと立ち上がります。",
        "",
        "",
    ],
    [
        "立ち上がった大きなカブはどこまでもどこまでも歩いていきましたが、どこまで行っても同じ景色です。",
        "",
    ],
    [
        "「この世界というのは思ったよりつまらないものだな」\n大きなカブはがっかりしてしまいましたとさ。\nおしまい！",
    ],
];
